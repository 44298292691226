export const environment = {
    production: true,
    apiUrl: 'https://api.stage.jumanmarket.com', //staging
    webUrl: "https://customer.stage.jumanmarket.com",
    rsa_public_key: "$2a$10$e.oPc.dyrwRoQCpDvO9Rhe",
    supplierUrl: 'https://supplier.stage.jumanmarket.com/login', //staging 
    appversion: "5.0", //app version
    firebaseConfig : {
        apiKey: "AIzaSyC10ShEcxI1Q-BVpwYbSt6LS6rwp-8J0nA",
        authDomain: "juman-marketplace-android.firebaseapp.com",
        projectId: "juman-marketplace-android",
        storageBucket: "juman-marketplace-android.appspot.com",
        messagingSenderId: "892620674733",
        appId: "1:892620674733:web:52d46ece5436734a3c1824",
        measurementId: "G-WT7WVZQ0Y3"
    },
    SENTRY_AUTH_TOKEN: "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"

};