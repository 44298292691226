<div class="slide-container" itemscope *ngIf="productlistPage == 'productlist1'" [class.product-border]="!categories"
	[ngClass]="['productDetailLabel' ? commonService.domDirectionLTR ? 'detail-slide-container' :'detail-slide-container-right' : '',productlistPage ? 'product-listing-card':'']"
	(click)="gotToProductDetail()">
	<div class="product-image-container"
		[ngClass]="categories ? 'categories-image-container' : 'product-image-container'">
		<div class="product-fav" (click)="addToWishlist($event, cardData.product_id, this.cardData.is_wishlist_product)"
			*ngIf="isLoggedIn && !wishListPage && !categories && !wishListTab">
			<ion-icon *ngIf="!this.cardData.is_wishlist_product" src="assets/icon/fav.svg" alt="juman"></ion-icon>
			<ion-icon *ngIf="this.cardData.is_wishlist_product" src="assets/icon/fav-liked.svg" alt="juman"></ion-icon>
		</div>
		<div *ngIf="cardData.discount_type &&  cardData.discount_type != 'PRICE' && !cardData.is_sold_out"
			class="product-discount">
			<div class="dis" [ngClass]="commonService.domDirectionLTR ? 'ltr' :'rtl'"> {{ cardData.discount_value }}%
			</div>
		</div>
		<div *ngIf="cardData.discount_type &&  cardData.discount_type == 'PRICE' && !cardData.is_sold_out"
			class="product-discount">
			<span class="dis" [ngClass]="commonService.domDirectionLTR ? 'ltr' :'rtl'">{{"OnSale" | translate}}</span>
		</div>
		<div class="product-list-image-div">
			<img *ngIf="cardData.product_images_web" [ngClass]="cardData.is_sold_out ? 'product-image':null"
				[src]="getPlatformService.isDesktop ? cardData.product_images_web : cardData.product_images_mobile"
				width="100%" height="100%" alt="juman">
			<img *ngIf="!cardData.product_images_web" src="assets/images/no-img1.png" width="100%" height="100%"
				[ngClass]="cardData.is_sold_out ? 'product-image':null" alt="juman">
		</div>
		<div *ngIf="cardData.is_sold_out" class="product-outofstock">
			<div class="outofstock-label">
				{{"outOfStock" | translate}}
			</div>
		</div>
	</div>
	<div class="detail-container" [ngClass]="cardData.take_action ? 'fixed' :''">
		<div class="upper-details">
			<div class="product-description text-left" itemprop="name"
				[ngClass]="[categories ? 'categories-product-description' : '']">
				{{commonService.domDirectionLTR ? cardData.product_name_en :
				cardData.product_name_ar
				}}
			</div>
			<div class="product-brand text-left" itemprop="brand" *ngIf="cardData.brand_name_en">
				<span>{{commonService.domDirectionLTR ? cardData.brand_name_en : cardData.brand_name_ar }} </span>
			</div>
		</div>
		<div class="items-available pt-5 text-left" *ngIf="cardData.items">
			{{ cardData.items }}
		</div>
		<div [ngClass]="[cardData.take_action ? 'h-50' :'', !categories ? 'price-detail' :'' ]">
			<div class="product-price text-left" *ngIf="!cardData.is_sold_out">
				<div class="from" *ngIf="productDetailLabel">
					{{"from" | translate}}
				</div>
				<div class="product-price-data" itemprop="price">
					<span class="currency">{{commonService.currencySymbol}}</span>
					<span class="price">{{cardData.final_price | number}}</span>
					<span class="unit pl-5" *ngIf="cardData.unit_name_en">/{{commonService.domDirectionLTR
						?cardData.unit_name_en : cardData.unit_name_ar }}
					</span>
				</div>
				<div class="product-price-data" itemprop="price">
					<span *ngIf="cardData.is_discount_applied" class="checked-price">
						<span class="currency2"
							*ngIf="commonService.domDirectionLTR">{{commonService.currencySymbol}}</span>
						<span class="price2">{{cardData.price_per_unit | number}}</span>
						<span class="currency2"
							*ngIf="!commonService.domDirectionLTR">{{commonService.currencySymbol}}</span>
					</span>

				</div>


			</div>
		</div>
		<!-- code required -->
		<!-- <p class="excluding-vat m-0" *ngIf="cardData.is_tax_free_product == true">
        {{'inclusiveVAT' | translate}}
    </p> -->
		<!-- <div class="notify-me" *ngIf="cardData.is_sold_out" (click)="notifyMeClick(cardData.product_id)">
        <app-common-button color="white" background="var(--ion-app-primary)" type="solid"
            title="{{'NotifyMe' |translate}}" [isHideInMobile]="false" [notifyMeButton]="true" [specificWidth]="'136px'"
            [specificHeight]="'39px'" [specificFontWeight]="'bold'"></app-common-button>
    </div> -->
		<div class="action-block" itemprop="action" *ngIf="cardData.take_action">
			<div class="left-block">
				<!-- code required -->
				<!-- <img class="edit" src="assets/icon/edit-review.svg" alt="Juman"> -->
				<img class="delete" (click)="removeFromWishList()" src="assets/icon/deleteIcon.svg" alt="Juman">
			</div>
			<div class="right-block">
				<span class="move" (click)="addToCart(cardData)" *ngIf="cardData.is_sub_product">{{"moveToCart" |
					translate}}</span>
				<span class="move" (click)="gotToProductDetail()" *ngIf="!cardData.is_sub_product">{{"toDetail" |
					translate}}</span>
			</div>
		</div>
	</div>
</div>



<div class="slide-container" itemscope *ngIf="productlistPage != 'productlist1'" [class.product-border]="!categories"
	[ngClass]="['productDetailLabel' ? commonService.domDirectionLTR ? 'detail-slide-container' :'detail-slide-container-right': '', fromSliderComponent ? 'from-slider-container' : '']">
	<div [ngClass]="categories ? 'categories-image-container' : 'product-image-container'"
		(click)="gotToProductDetail()">
		<div class="product-fav" (click)="addToWishlist($event, cardData.product_id, this.cardData.is_wishlist_product)"
			*ngIf="isLoggedIn && !wishListPage && !categories && !wishListTab">
			<ion-icon *ngIf="!this.cardData.is_wishlist_product" src="assets/icon/fav.svg" alt="juman"></ion-icon>
			<ion-icon *ngIf="this.cardData.is_wishlist_product" src="assets/icon/fav-liked.svg" alt="juman"></ion-icon>
		</div>
		<div *ngIf="cardData.discount_type && cardData.discount_type != 'PRICE' && !cardData.is_sold_out"
			class="product-discount">
			<div class="dis" [ngClass]="commonService.domDirectionLTR ? 'ltr' :'rtl'"> {{cardData.discount_value }}%
			</div>
		</div>
		<div *ngIf="cardData.discount_type &&  cardData.discount_type == 'PRICE' && !cardData.is_sold_out"
			class="product-discount">
			<span class="dis" [ngClass]="commonService.domDirectionLTR ? 'ltr' :'rtl'">{{"OnSale" | translate}}</span>
		</div>
		<div class="product-image-div" [ngClass]="searchPage ? 'product-search-image-div' : ''">
			<img [ngClass]="[cardData.is_sold_out ? 'product-image' : '', wishListPage ? 'wishListImg' : '']"
				src="{{ cardData.product_img }}" alt="Juman">
		</div>
		<div *ngIf="cardData.is_sold_out" class="product-outofstock">
			<div class="outofstock-label">
				{{"outOfStock" | translate}}
			</div>
		</div>
	</div>
	<div class="detail-container" [ngClass]="cardData.take_action ? 'fixed' :''">
		<div class="upper-details">
			<div (click)="gotToProductDetail()" itemprop="name" class="product-description text-left"
				[ngClass]="[categories ? 'categories-product-description' : '']">
				{{ commonService.domDirectionLTR ? cardData.product_name : cardData.product_name_ar}}
			</div>
			<div (click)="gotToProductDetail()" itemprop="brand" class="product-brand text-left"
				*ngIf="cardData.brand_name">
				{{commonService.domDirectionLTR ? cardData.brand_name : cardData.brand_name_ar }}
			</div>
		</div>
		<div (click)="gotToProductDetail()" class="items-available pt-5 text-left" *ngIf="cardData.items">
			{{ cardData.items }}
		</div>
		<div class.="price-detail" [ngClass]="[cardData.take_action ? 'h-50' :'', !categories ? 'price-detail' :'' ]">
			<div class="product-price text-left" *ngIf="!cardData.is_sold_out && cardData.product_price">
				<div class="from" *ngIf="productDetailLabel">
					{{"from" | translate}}
				</div>
				<div class="product-price-data" itemprop="price">
					<span class="currency">{{ commonService.currencySymbol }}</span>
					<span class="price">{{cardData.final_price| number }}</span>
					<span class="unit pl-5">/{{ commonService.domDirectionLTR ? cardData.product_unit :
						cardData.product_unit_arb }}</span>
				</div>
				<div class="product-price-data" itemprop="price">
					<span *ngIf="cardData.is_discount_applied" class="checked-price currency2">
						<span class="currency2">{{commonService.currencySymbol}}</span>
						<span class="price2">{{cardData.price_per_unit | number}}</span>
					</span>
				</div>
				<!-- code required -->
				<!-- <p class="excluding-vat m-0" *ngIf="cardData.is_tax_free_product == true">
            {{'inclusiveVAT' | translate}}
        </p> -->
			</div>
		</div>

		<!-- <div class="notify-me" *ngIf="cardData.is_sold_out && !cardData.take_action"
        (click)="notifyMeClick(cardData.product_id)">
        <app-common-button color="white" background="var(--ion-app-primary)" type="solid"
            title="{{'NotifyMe' |translate}}" [isHideInMobile]="false" [notifyMeButton]="true" [specificWidth]="'136px'"
            [specificHeight]="'39px'" [specificFontWeight]="'bold'"></app-common-button>
    </div> -->
		<div class="action-block" itemprop="action" *ngIf="cardData.take_action">
			<div class="left-block">
				<!-- code-required -->
				<!-- <img class="edit" src="assets/icon/edit-review.svg" alt="Juman"> -->
				<img class="delete" (click)="removeFromWishList()" src="assets/icon/deleteIcon.svg" alt="Juman">
			</div>
			<div class="right-block  mr-10">
				<span class="move" (click)="gotoSelectVariant(cardData.product_id,cardData.sub_product_id)"
					*ngIf="!cardData.is_sold_out">{{"moveToCart"
					|
					translate}}</span>

				<!-- <span class="move" (click)="notifyMeClick(cardData.product_id)" *ngIf="cardData.is_sold_out">{{"NotifyMe" |
                translate}}</span> -->
			</div>
		</div>
	</div>
</div>