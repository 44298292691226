import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications, Token } from '@capacitor/push-notifications';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CapacitorCookieService } from './capacitor-cookie.service';
import { CommonService } from './common.service';
import { GetPlatformService } from './get-platform.service';
import { ModalService } from './modal.service';


@Injectable({
	providedIn: 'root'
})
export class PushnotificationService {

	constructor(private router: Router,
		private commonService: CommonService,
		public translateService: TranslateService,
		private getPlatformService: GetPlatformService,
		private navCtrl: NavController,
		public modalService: ModalService,
		public zone: NgZone,
		private capacitorCookieService: CapacitorCookieService) { }

	remoteToken: any;

	initPush() {
		if (Capacitor.getPlatform() !== 'web') {
			this.registeringPushNotification();
		}
	}

	public registeringPushNotification() {
		// Request permission to use push notifications
		// iOS will prompt user and return if they granted permission or not
		// Android will just grant without prompting
		PushNotifications.requestPermissions().then(result => {
			if (result.receive === 'granted') {
				// Register with Apple / Google to receive push via APNS/FCM
				PushNotifications.register();
			} else {
				// Show some error
			}
		});

		// On success, we should be able to receive notifications
		PushNotifications.addListener('registration',
			(token: Token) => {
				this.commonService.cookieSet('notification_token', token.value);
				this.capacitorCookieService.setCookie('notification_token', token.value);
			}
		);

		// Show us the notification payload if the app is open on our device
		PushNotifications.addListener('pushNotificationReceived',
			(notification: any) => {
				console.log(JSON.stringify(notification) + 'pushNotificationReceived');
			}
		);

		// Method called when tapping on a notification
		PushNotifications.addListener('pushNotificationActionPerformed',
			(notification: ActionPerformed) => {
				console.log("Perfomred == >", JSON.stringify(notification));

				this.navigationOfNotification(notification.notification)
			}
		);
	}

	navigationOfNotification(notificatonPayload) {
		let payload;
		let checkPlatform;

		if (Capacitor.getPlatform() === 'ios') {
			payload = notificatonPayload.data.aps
		}
		else {
			payload = notificatonPayload.data
		}
		checkPlatform = payload.notification_type
		this.modalService.dismissModal();
		this.zone.run(() => {
			switch (checkPlatform) {
				case 'Coupons': {
					//statements;
					this.navCtrl.navigateRoot("/" + this.commonService.currentLang + ['/cart'], { animated: false });
					break;
				}
				case 'New Orders': {
					//statements;
					this.navCtrl.navigateRoot("/" + this.commonService.currentLang + ['/home/order-details-mobile'], {
						animated: false,
						queryParams: {
							invoiceId: payload.reference_id
						}
					})

					break;
				}
				case 'News/Events/Announcements': {
					//statements;
					this.navCtrl.navigateRoot("/" + this.commonService.currentLang + ['/home'], { animated: false });
					break;
				}
				case 'Return/Cancel Orders': {
					//statements;
					this.navCtrl.navigateRoot("/" + this.commonService.currentLang + ['/home'], { animated: false });
					break;
				}
				case 'Product Stock Status': {
					//statements;
					this.navCtrl.navigateRoot("/" + this.commonService.currentLang + '/home/product-detail/' + payload.reference_id, { animated: false });
					break;
				}
				case 'Products and Brands status': {
					//statements;
					break;
				}
				case 'Account Information': {
					//statements;
					this.navCtrl.navigateRoot("/" + this.commonService.currentLang + ['/my-account/my-account-details'], { animated: false });
					break;
				}
				default: {
					//statements;
					this.navCtrl.navigateRoot("/" + this.commonService.currentLang + ['/home'], { animated: false });
					break;
				}
			}
		});
	}
}
