import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonAccordionGroup, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';

@Component({
	selector: 'app-filter',
	templateUrl: './filter.component.html',
	styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {

	@Input() priceRange;
	@Input() maximumPrice: any = 0;
	@Input() catagoryDatas = [];
	@Input() regionDatas = [];
	@Input() brandDatas = [];
	@Input() supplierDatas = [];
	@Input() groupDatas = [];
	@Input() isAccordianDisabled: boolean = false;
	@Output() callFilterData = new EventEmitter();
	@Output() filterByData = new EventEmitter();
	private subscription: Subscription = new Subscription();
	lowerRangeValue: any;
	appliedPriceRange: any;
	upperRangeValue: any;
	minimunPrice = 0;
	all = true;
	isPriceOpen = false;
	isSupplierOpen = false;
	isRegionOpen = false;
	isBrandOpen = false;
	isCategoryOpen = false;
	category = true;
	price = true;
	groupValue = ['first', 'second', 'third', 'fourth', 'fifth']
	region = true;
	showAccordian = true;
	isGroupChecked = false;
	brand = true;
	group = true;
	supplier = true;
	maxPrice: any;
	minPrice: any;
	filterBy = [];
	filtercatagory = [];
	filterGroup = [];
	filterCategoryData = [];
	filterGroupData = []
	filterRegionData = [];
	filterSupplierData = [];
	filterBrandData = [];
	filterRegion = [];
	filterBrand = [];
	filterSupplier = [];
	selectedFilters = [];
	@Input() categoryCheckbox = [];
	@Input() brandCheckbox = [];
	@Input() supplierCheckbox = [];
	@Input() regionCheckboxes = [];
	@Input() groupCheckbox = [];
	selectedGroup = [];
	dataRangePin: boolean = true;
	dateRangeDisable: boolean = false;
	public filterValue: any;
	filterOptions = [
		{
			filterName: 'group',
			isOpen: false,
			value: 'group',
			checkboxes: this.groupCheckbox,
			filterTitle: "Select Group",
			filterTitle_ar: "اختر مجموعة",
			inList: true,
			selected: '',
		},
		{
			filterName: 'category',
			isOpen: false,
			value: 'catagory',
			checkboxes: this.categoryCheckbox,
			filterTitle: "Select Categories",
			filterTitle_ar: "حدد الفئات",
			inList: true,
			selected: '',
		},
		{
			filterName: 'priceRange',
			isOpen: false,
			value: 'price',
			checkboxes: [],
			filterTitle: "Select Price",
			filterTitle_ar: "حدد السعر",
			inList: false,
			selected: ''
		},
		// code maybe required
		// {
		//     filterName: 'region',
		//     isOpen: false,
		//     value: 'region',
		//     checkboxes: this.regionCheckboxes,
		//     filterTitle: "Select Region",
		//     inList: true,
		//     selected: ''
		// },
		{
			filterName: 'brand',
			isOpen: false,
			value: 'brand',
			checkboxes: this.brandCheckbox,
			filterTitle: "Select Brand",
			filterTitle_ar: "حدد العلامة التجارية",
			inList: true,
			selected: ''
		},
		{
			filterName: 'supplier',
			isOpen: false,
			value: 'supplier',
			checkboxes: this.supplierCheckbox,
			filterTitle: "Select Supplier",
			filterTitle_ar: "حدد المورد",
			inList: true,
			selected: ''
		}
	];
	title = "selectCategory";

	@ViewChild('accordionGroup', { static: true }) accordionGroup: IonAccordionGroup;
	constructor(private modalCtrl: ModalController,
		public commonService: CommonService,
		private dataService: ApiService,
		public getPlatformService: GetPlatformService,
		private navCtrl: NavController, public translate: TranslateService) { }

	ngOnInit() {
		let filterOption = JSON.parse(this.commonService.localStorageGet("filter_options"));
		if (!this.getPlatformService.isDesktop) {
			this.filterOptions = [
				{
					filterName: 'group',
					isOpen: false,
					value: 'group',
					checkboxes: this.groupCheckbox,
					filterTitle: "Select Group",
					filterTitle_ar: "اختر مجموعة",
					inList: true,
					selected: '',
				},
				{
					filterName: 'priceRange',
					isOpen: false,
					value: 'price',
					checkboxes: [],
					filterTitle: "Select Price",
					filterTitle_ar: "حدد السعر",
					inList: false,
					selected: ''
				},
				{
					filterName: 'category',
					isOpen: false,
					value: 'catagory',
					checkboxes: this.categoryCheckbox,
					filterTitle: "Select Categories",
					filterTitle_ar: "حدد الفئات",
					inList: true,
					selected: '',
				},
				// code maybe required
				// {
				//     filterName: 'region',
				//     isOpen: false,
				//     value: 'region',
				//     checkboxes: this.regionCheckboxes,
				//     filterTitle: "Select Region",
				//     inList: true,
				//     selected: ''
				// },
				{
					filterName: 'brand',
					isOpen: false,
					value: 'brand',
					checkboxes: this.brandCheckbox,
					filterTitle: "Select Brand",
					filterTitle_ar: "حدد العلامة التجارية",
					inList: true,
					selected: ''
				},
				{
					filterName: 'supplier',
					isOpen: false,
					value: 'supplier',
					checkboxes: this.supplierCheckbox,
					filterTitle: "Select Supplier",
					filterTitle_ar: "حدد المورد",
					inList: true,
					selected: ''
				}
			];
		}

		if (!this.getPlatformService.isDesktop && filterOption) {
			this.filterCategoryData = [];
			this.filterOptions = filterOption
			this.filterOptions.forEach(element => {
				if (element.filterName == "category") {
					this.categoryCheckbox = element.checkboxes
				}
				if (element.filterName == "group") {
					this.groupCheckbox = element.checkboxes
				}
				if (element.filterName == "brand") {
					this.brandCheckbox = element.checkboxes
				}
				if (element.filterName == "supplier") {
					this.supplierCheckbox = element.checkboxes
				}
			});

		} else {
			setTimeout(() => {
				this.groupDatas.forEach(element => {
					this.groupCheckbox.push({
						label: element["group_name_en"],
						label_ar: element["group_name_ar"],
						value: element["group_id"],
						isChecked: element["isChecked"],
						group_id: element["group_id"],
						type: "group",
						filter_id: 5
					});
				});
				this.catagoryDatas.forEach(element => {
					this.categoryCheckbox.push({
						label: element['category_name_en'],
						label_ar: element['category_name_ar'],
						category_image_url: element['category_image_url'],
						value: element['category_id'],
						isChecked: element['isChecked'],
						type: 'catagory',
						filter_id: 1
					})
				});
				// code maybe required
				// this.regionDatas.forEach(element => {
				//     this.regionCheckboxes.push({
				//         label: element['region_en'],
				//         label_ar: element['region_ar'],
				//         value: element['region_id'],
				//         isChecked: element['isChecked'],
				//         type: 'region',
				//         filter_id: 2
				//     })
				// });
				this.brandDatas.forEach(element => {
					this.brandCheckbox.push({
						label: element['brand_name_en'],
						label_ar: element['brand_name_ar'],
						value: element['brand_id'],
						isChecked: element['isChecked'],
						type: 'brand',
						filter_id: 3
					})
				});
				this.supplierDatas.forEach(element => {
					this.supplierCheckbox.push({
						label: element['supplier_name_en'],
						label_ar: element['supplier_name_ar'],
						value: element['supplier_id'],
						isChecked: element['isChecked'],
						type: 'supplier',
						filter_id: 4
					})
				});

				let category_id = this.commonService.localStorageGet("category_id");
				if (category_id) {
					this.categoryCheckbox.forEach((element, index) => {
						if (element.value === +category_id && element.type == 'catagory') {
							this.filterBy.push(element);
							if (!this.filterCategoryData.includes(element.label)) {
								this.filterCategoryData.push(element.label);
								this.filtercatagory.push(+category_id);
							}
							this.filter();
						}
						this.filterOptions.forEach(filterElement => {
							if (filterElement.value === element.type) {
								filterElement.isOpen = true;
								this.filterValue = filterElement.value;
							} else {
								filterElement.isOpen = false;
							}
						});
					});
				}
			});
		}
		if (this.priceRange) {
			this.minPrice = this.priceRange['min_product_price'] ? Math.round(+this.priceRange['min_product_price']) : 0;
			this.maxPrice = this.priceRange['max_product_price'] ? Math.round(+this.priceRange['max_product_price']) : 0;
			this.minimunPrice = 0;
			this.maximumPrice = this.priceRange['max_product_price'] ? Math.round(+this.priceRange['max_product_price']) : 0;
			this.lowerRangeValue = this.priceRange['min_product_price'] ? Math.round(+this.priceRange['min_product_price']) : 0;
			this.upperRangeValue = this.priceRange['max_product_price'] ? Math.round(+this.priceRange['max_product_price']) : 0;
		} else {
			this.getPriceRange();
		}
		if (!this.maximumPrice) {
			this.maximumPrice = 0;
		}
		if (this.minPrice == 0 && this.maxPrice == 0) {
			this.dataRangePin = false;
			this.dateRangeDisable = true;
		}
	}

	filter() {
		this.filterByData.emit({
			filter_by: this.filterBy
		})
	}

	async onClick(filter) {
		const modal = await this.modalCtrl.create({
			component: FilterComponent,
			componentProps: {
				category: filter.filterName == 'category' ? true : false,
				price: false,
				region: filter.filterName == 'region' ? true : false,
				supplier: filter.filterName == 'supplier' ? true : false,
				brand: filter.filterName == 'brand' ? true : false,
				group: filter.filterName == 'group' ? true : false,
				all: false,
				categoryCheckbox: this.categoryCheckbox,
				regionCheckboxes: this.regionCheckboxes,
				supplierCheckbox: this.supplierCheckbox,
				brandCheckbox: this.brandCheckbox,
				groupCheckbox: this.groupCheckbox,
				title: this.commonService.domDirectionLTR ? filter.filterTitle : filter.filterTitle_ar,
				showAccordian: this.showAccordian,
				filtercatagory: this.filtercatagory,
				filterBrand: this.filterBrand,
				filterRegion: this.filterRegion,
				filterSupplier: this.filterSupplier,
				filterGroup: this.filterGroup,
				filterCategoryData: this.filterCategoryData,
				filterRegionData: this.filterRegionData,
				filterBrandData: this.filterBrandData,
				filterSupplierData: this.filterSupplierData,
				filterGroupData: this.filterGroupData
			}
		});
		modal.present();
		modal.onWillDismiss().then((data) => {
			if (data && data['data']) {
				if (data['data'].category) {
					this.filtercatagory = data['data'].category;
					this.filterCategoryData = data['data'].filterCategoryData
					this.categoryCheckbox = data['data'].categoryCheckbox
				}
				if (data['data'].brand) {
					this.filterBrand = data['data'].brand;
					this.filterBrandData = data['data'].filterBrandData
					this.brandCheckbox = data['data'].brandCheckbox
				}
				// if (data['data'].region) {
				//     this.filterRegion = data['data'].region;
				//     this.filterRegionData = data['data'].filterRegionData
				// }
				if (data['data'].supplier) {
					this.filterSupplier = data['data'].supplier;
					this.filterSupplierData = data['data'].filterSupplierData
					this.supplierCheckbox = data['data'].supplierCheckbox
				}
				if (data['data'].group) {
					this.filterGroupData = data['data'].group;
					this.filterGroupData = data['data'].filterGroupData
					this.groupCheckbox = data['data'].groupCheckbox
				}
			}
		})
	}

	selectCheckbox(event, option, checkboxOption, filter_id?) {
		event.preventDefault();
		event.stopPropagation();
		checkboxOption.forEach(element => {
			if (option.value == element.value) {
				option.isChecked = event.detail.checked;
			}
		});
		if (option.isChecked) {
			this.filterBy.push(option);
			if (filter_id == 5) {
				this.filterCategoryData = [];
				this.isGroupChecked = option.isChecked;
				this.selectedGroup.push({
					group_id: option.group_id,
					label: option.label
				});
			}
		}
		else {
			const index = this.filterBy.indexOf(option);
			const categoryIndex = this.filterCategoryData.indexOf(option.label);
			const regionIndex = this.filterRegionData.indexOf(option.label);
			const brandIndex = this.filterBrandData.indexOf(option.label);
			const supplierIndex = this.filterSupplierData.indexOf(option.label);
			const groupIndex = this.filterGroupData.indexOf(option.label);
			if (index > -1) {
				this.filterBy.splice(index, 1);
			}
			if (categoryIndex > -1) {
				this.filterCategoryData.splice(categoryIndex, 1);
			}
			if (regionIndex > -1) {
				this.filterRegionData.splice(regionIndex, 1);
			}
			if (brandIndex > -1) {
				this.filterBrandData.splice(brandIndex, 1);
			}
			if (supplierIndex > -1) {
				this.filterSupplierData.splice(supplierIndex, 1);
			}
			if (groupIndex > -1) {
				this.filterGroupData.splice(groupIndex, 1);
			}
			if (filter_id == 5) {
				this.filterCategoryData = [];
				this.isGroupChecked = option.isChecked;
				const groupIndex = this.selectedGroup.findIndex(obj => obj.label === option.label);
				if (groupIndex > -1) {
					this.selectedGroup.splice(groupIndex, 1);
				}
				if (this.selectedGroup.length == 0) {
					this.updateCategoryListing();
				}
			}
		}
		if (filter_id == 5) {
			let selectedGroupFilter = [];
			this.filterCategoryData = [];
			this.selectedGroup.forEach(element => {
				selectedGroupFilter.push(element.group_id);
			});
			let selectedGroupStr = selectedGroupFilter.join(',');
			if (selectedGroupStr) {
				this.updateCategoryListing(selectedGroupStr);
			}
		}
		this.checkboxFilterSelection(filter_id, option.type);
	}

	updateCategoryListing(selectedGroupStr?) {
		let params = {};
		if (selectedGroupStr) {
			params['group_id'] = selectedGroupStr;
		}
		this.subscription.add(
			this.dataService.get(URLS.categoryListFilter, params).subscribe(
				(data) => {
					if (data["code"] == 200) {
						let categoryListData = data["data"]["result"];
						let categoryCheckboxData = [];
						this.filtercatagory = [];
						categoryListData.forEach((element) => {
							categoryCheckboxData.push({
								label: element['category_name_en'],
								label_ar: element['category_name_ar'],
								value: element['category_id'],
								isChecked: element['isChecked'],
								type: 'catagory',
								filter_id: 1
							});
						});

						this.filterOptions.forEach(filterElement => {
							if (filterElement.filterName == "category") {
								filterElement['checkboxes'] = categoryCheckboxData
								this.categoryCheckbox = categoryCheckboxData
							}
						});

						this.commonService.localStorageSet("filter_options", JSON.stringify(this.filterOptions))

						this.filterBy = this.filterBy.filter(obj => obj.type != "catagory");

					} else {
					}
				},
				(error) => {

				}
			)
		);
	}

	removeFilterOption(option) {
		this.filterBy.forEach((element, i) => {
			if (option.value === element.value) {
				this.filterBy.splice(i, 1);
			}
		});
		this.groupCheckbox.forEach(element => {
			if (option.value === element.value) {
				element.isChecked = false;
			}
		});
		this.categoryCheckbox.forEach(element => {
			if (option.value === element.value) {
				element.isChecked = false;
			}
		});
		this.regionCheckboxes.forEach(element => {
			if (option.value === element.value) {
				element.isChecked = false;
			}
		});
		this.supplierCheckbox.forEach(element => {
			if (option.value === element.value) {
				element.isChecked = false;
			}
		});
		this.brandCheckbox.forEach(element => {
			if (option.value === element.value) {
				element.isChecked = false;
			}
		});
		this.groupCheckbox.forEach(element => {
			if (option.value === element.value) {
				element.isChecked = false;
			}
		})
		this.checkboxFilterSelection();

	}
	checkboxFilterSelection(filter_id?, type?) {
		if (filter_id == 1) {
			this.filtercatagory = [];
		}
		if (filter_id == 2) {
			this.filterRegion = [];
		}
		if (filter_id == 3) {
			this.filterBrand = [];
		}
		if (filter_id == 4) {
			this.filterSupplier = [];
		}
		if (filter_id == 5) {
			this.filterGroup = [];
		}
		this.filterBy.forEach(element => {
			if (element.type == 'catagory' && element.type == type) {
				this.filtercatagory.push(element.value);
				if (!this.filterCategoryData.includes(element.label)) {
					this.filterCategoryData.push(element.label);
				}
			}
			// code maybe required
			// if (element.type == 'region' && element.type == type) {
			//     this.filterRegion.push(element.value)
			//     if (!this.filterRegionData.includes(element.label)) {
			//         this.filterRegionData.push(element.label);
			//     }
			// }
			if (element.type == 'brand' && element.type == type) {
				this.filterBrand.push(element.value)
				if (!this.filterBrandData.includes(element.label)) {
					this.filterBrandData.push(element.label);
				}
			}
			if (element.type == 'supplier' && element.type == type) {
				this.filterSupplier.push(element.value)
				if (!this.filterSupplierData.includes(element.label)) {
					this.filterSupplierData.push(element.label);
				}
			}
			if (element.type == 'group' && element.type == type) {
				this.filterGroup.push(element.value)
				if (!this.filterGroupData.includes(element.label)) {
					this.filterGroupData.push(element.label);
				}
			}
		});

		setTimeout(() => {
			this.passFilterDatas();
			this.filter();
			if (this.filterBy.length === 0) {
				this.navCtrl.navigateForward("/" + this.commonService.currentLang + '/home/product-listing');
			}
		}, 500);

	}

	getFilterCategoryBackground(index) {
		return { 'background': index === 0 ? '#38C2D4' : (index === 1 ? '#6AD1DF' : (index === 2 ? '#9BE0E9' : (index === 3 ? '#CDF0F4' : '#e6faff'))) };
	}

	// slider end change detect
	onIonKnobMoveEnd(event) {
		event.preventDefault();
		event.stopPropagation();
		this.lowerRangeValue = event['detail']['value']['lower'];
		this.upperRangeValue = event['detail']['value']['upper'];
		this.minPrice = this.lowerRangeValue;
		this.maxPrice = this.upperRangeValue;
		setTimeout(() => {
			this.passFilterDatas()
		}, 500);
	}

	accordionGroupChange(event) {
		const selectedValue = event.detail.value;
		this.filterOptions.forEach(element => {
			if (selectedValue.includes(element.value)) {
				element.isOpen = true;
			} else {
				element.isOpen = false;
			}

		});
	}

	applyFiltersMobile() {
		this.modalCtrl.dismiss({
			rangeLower: this.lowerRangeValue,
			rangeUpper: this.upperRangeValue,
			category: this.filtercatagory.length == 0 ? null : this.filtercatagory,
			group: this.filterGroup.length == 0 ? null : this.filterGroup,
			// region: this.filterRegion.length == 0 ? null : this.filterRegion,
			brand: this.filterBrand.length == 0 ? null : this.filterBrand,
			supplier: this.filterSupplier.length == 0 ? null : this.filterSupplier,
			categoryData: this.categoryCheckbox,
			regionData: this.regionCheckboxes,
			brandData: this.brandCheckbox,
			supplierData: this.supplierCheckbox,
			filterCategoryData: this.filterCategoryData,
			filterRegionData: this.filterRegionData,
			filterBrandData: this.filterBrandData,
			filterSupplierData: this.filterSupplierData,
			filterGroupData: this.filterGroupData,
			priceData: {
				max_product_price: this.upperRangeValue,
				min_product_price: this.lowerRangeValue,
			}
		})


		setTimeout(() => {
			this.passFilterDatas();
		}, 500);
	}

	getPriceRange() {
		this.subscription.add(
			this.dataService.get(URLS.priceRange).subscribe(data => {
				if (data['code'] == 200) {
					this.priceRange = data['data'];
					this.minPrice = data['data']['min_product_price'] ? Math.round(+data['data']['min_product_price']) : 0;
					this.maxPrice = data['data']['max_product_price'] ? Math.round(+data['data']['max_product_price']) : 0;
					this.minimunPrice = 0;
					this.maximumPrice = data['data']['max_product_price'] ? Math.round(+data['data']['max_product_price']) : 0;
					this.lowerRangeValue = data['data']['min_product_price'] ? Math.round(+data['data']['min_product_price']) : 0;
					this.upperRangeValue = data['data']['max_product_price'] ? Math.round(+data['data']['max_product_price']) : 0;
				}
				else {
					this.priceRange = [];
				}
			})
		)
	}

	clearFiltersMobile() {
		this.commonService.localStorageSet("category_id", null);
		this.minPrice = this.minimunPrice;
		this.maxPrice = this.maximumPrice;
		this.lowerRangeValue = this.minPrice;
		this.upperRangeValue = this.maxPrice;
		this.filterCategoryData = [];
		this.filterBrandData = [];
		this.filterRegionData = [];
		this.filterSupplierData = [];
		this.filterGroupData = [];
		this.categoryCheckbox.forEach(element => {
			element['isChecked'] = false;
		});
		this.regionCheckboxes.forEach(element => {
			element['isChecked'] = false;
		});
		this.brandCheckbox.forEach(element => {
			element['isChecked'] = false;
		});
		this.supplierCheckbox.forEach(element => {
			element['isChecked'] = false;
		});
		this.applyFiltersMobile();
	}
	goBack() {
		if (this.title === "Filter By") {
			let filtercatagory = [];
			let filterCategoryData = [];
			let category_id = this.commonService.localStorageGet("category_id");
			if (category_id) {
				this.categoryCheckbox.forEach((element, index) => {
					if (element.value === +category_id && element.type == 'catagory') {
						if (!filterCategoryData.includes(element.label)) {
							filterCategoryData.push(element.label);
							filtercatagory.push(+category_id);
						}
					}
				})
			}

			this.filterBrand = [];
			this.filterSupplier = [];
			this.filterRegionData = [];
			this.filterBrandData = [];
			this.filterSupplierData = [];
			this.filterGroupData = [];
			this.regionCheckboxes.forEach(element => {
				element['isChecked'] = false;
			});
			this.brandCheckbox.forEach(element => {
				element['isChecked'] = false;
			});
			this.supplierCheckbox.forEach(element => {
				element['isChecked'] = false;
			});
			this.brandDatas.forEach(element => {
				element['isChecked'] = false;
			});
			this.supplierDatas.forEach(element => {
				element['isChecked'] = false;
			});
			this.modalCtrl.dismiss({
				category: filtercatagory.length == 0 ? null : filtercatagory,
				// region: this.filterRegion.length == 0 ? null : this.filterRegion,
				brand: this.filterBrand.length == 0 ? null : this.filterBrand,
				supplier: this.filterSupplier.length == 0 ? null : this.filterSupplier,
				group: this.filterGroup.length == 0 ? null : this.filterGroup,
				filterCategoryData: filterCategoryData,
				filterRegionData: this.filterRegionData,
				filterBrandData: this.filterBrandData,
				filterSupplierData: this.filterSupplierData,
				filterGroupData: this.filterGroupData,
				brandData: [],
				supplierData: [],
				regionData: [],
				rangeLower: this.lowerRangeValue,
				rangeUpper: this.upperRangeValue,
			})

		}
		else {
			this.modalCtrl.dismiss({
				category: this.filtercatagory.length == 0 ? null : this.filtercatagory,
				// region: this.filterRegion.length == 0 ? null : this.filterRegion,
				brand: this.filterBrand.length == 0 ? null : this.filterBrand,
				supplier: this.filterSupplier.length == 0 ? null : this.filterSupplier,
				group: this.filterGroup.length == 0 ? null : this.filterGroup,
				filterCategoryData: this.filterCategoryData,
				filterRegionData: this.filterRegionData,
				filterBrandData: this.filterBrandData,
				filterSupplierData: this.filterSupplierData,
				filterGroupData: this.filterGroupData,
				categoryCheckbox: this.categoryCheckbox,
				regionCheckboxes: this.regionCheckboxes,
				supplierCheckbox: this.supplierCheckbox,
				brandCheckbox: this.brandCheckbox,
				groupCheckbox: this.groupCheckbox,
				rangeLower: this.lowerRangeValue,
				rangeUpper: this.upperRangeValue,
			})
		}

		if (this.title === "filterBy") {
			this.commonService.localStorageRemove("filter_options")
		}

		if (this.title === "Select Group") {
			this.filterCategoryData = [];
		}

	}

	rangeChange(event) {
		event.preventDefault();
		event.stopPropagation();
		this.lowerRangeValue = event['detail']['value']['lower'];
		this.upperRangeValue = event['detail']['value']['upper'];
	}

	passFilterDatas() {
		this.callFilterData.emit({
			rangeLower: this.lowerRangeValue,
			rangeUpper: this.upperRangeValue,
			category: this.filtercatagory.length == 0 ? null : this.filtercatagory,
			group: this.filterGroup.length == 0 ? null : this.filterGroup,
			// region: this.filterRegion.length == 0 ? null : this.filterRegion,
			brand: this.filterBrand.length == 0 ? null : this.filterBrand,
			supplier: this.filterSupplier.length == 0 ? null : this.filterSupplier,
			category_data: this.filterCategoryData ? this.filterCategoryData : null
		})
	}

	getArabicText(label) {
		let arText: any;
		this.categoryCheckbox.forEach(element => {
			if (element.label == label) {
				arText = element.label_ar;
			}
		});
		this.regionCheckboxes.forEach(element => {
			if (element.label == label) {
				arText = element.label_ar;
			}
		});
		this.brandCheckbox.forEach(element => {
			if (element.label == label) {
				arText = element.label_ar;
			}
		});
		this.supplierCheckbox.forEach(element => {
			if (element.label == label) {
				arText = element.label_ar;
			}
		});
		return arText;
	}
}
