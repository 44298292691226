import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { GetPlatformService } from 'src/app/services/get-platform.service';

@Injectable({
	providedIn: 'root'
})
export class ModalService {
	constructor(private modalCtrl: ModalController,
		public getPlatformService: GetPlatformService,
		@Inject(PLATFORM_ID) private platformId: Object,

		private navCtrl: NavController) {
	}

	public modal: any;

	async openModal(modalOptions, callback?, callback2?, cartElement?) {
		if (!modalOptions) {
			return;
		}
		if (!modalOptions['component']) {
			return;
		}
		modalOptions['componentProps'] = {
			...modalOptions['componentProps'],
			isDesktop: this.getPlatformService.isDesktop,
		}
		let obj = {
			componentProps: { isDesktop: this.getPlatformService.isDesktop },
		}
		if (!this.getPlatformService.isDesktop) {
			obj['initialBreakpoint'] = 0.5;
		}
		if (!this.getPlatformService.isDesktop && modalOptions['id'] === "twoFaModal") {
			obj['initialBreakpoint'] = 0;
			obj['backdropBreakpoint'] = 0.25;
			modalOptions['cssClass'] = 'twoFaModal'
		}
		if (this.getPlatformService.isDesktop && modalOptions['id'] === "forgotPassword" || modalOptions['id'] === "twoFaModal") {
			modalOptions['backdropDismiss'] = false;
		}
		if (this.getPlatformService.isDesktop && modalOptions['id'] === "registerModal") {
			modalOptions['cssClass'] = 'register-Modal';
		}

		if (this.getPlatformService.isDesktop && modalOptions['id'] === "sampleModal") {
			modalOptions['cssClass'] = 'sample-modal';
		}
		else if (!this.getPlatformService.isDesktop && modalOptions['id'] === "sampleModal") {
			modalOptions['cssClass'] = 'sample-modal-mobile';
		}

		this.modal = await this.modalCtrl.create(modalOptions);

		// Focus management: move focus to the first focusable element inside the modal
		const modalElement = this.modal && this.modal.getRootNode();
		const firstFocusableElement = modalElement && modalElement.querySelector('button') || modalElement && modalElement.querySelector('input');
		if (firstFocusableElement) {
			firstFocusableElement.focus();
		}


		if (cartElement) {
			const parentPos = cartElement.nativeElement.getBoundingClientRect();
			if (parentPos) {
				const modalEl = this.modal;
				if (parentPos.right > 1739) {
					modalEl.style.right = `${parentPos.right - 1800}px`;
				} else {
					modalEl.style.right = `${0}%`;
				}
			}
		}

		if (this.modal) {
			this.modal.present();
		} else {
			console.log('Modal is not defined');
		}

		if (this.modal) {
			return await this.modal.onDidDismiss();
		} else {
			return console.log('Modal is not defined');
		}

		// const { data, role } = await this.modal.onWillDismiss();

		//    return this.modal.onDidDismiss().then((data) => {
		//         if (callback) {
		//             callback();
		//         }
		//         if (callback2) {
		//             callback2();
		//         }

		//         return data;
		//     })

		//     return this.modal

	}
	dismissModal(dissmissOptions?) {
		if (this.modal) {
			let isModalOpen;
			if (!isPlatformServer(this.platformId)) {

				this.modalCtrl.getTop().then((data) => {
					isModalOpen = data;
					if (isModalOpen) {
						this.modalCtrl.dismiss(dissmissOptions);
					}
				});
			}
		}
	}


	dismissModalwithData(value) {
		if (this.modal) {
			this.modalCtrl.dismiss({ data: value });
		}


	}

	dismissAllModal() {
		this.dismissModal();
		if (this.modal) {
			let isModalOpen;
			if (!isPlatformServer(this.platformId)) {
				this.modalCtrl.getTop().then((data) => {
					isModalOpen = data;
					if (isModalOpen) {
						this.modal.onDidDismiss().then(() => {
							this.modalCtrl.dismiss();
						});
					}
				});
			}


		}
	}

}
