import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IonInput, NavController, Platform } from '@ionic/angular';
import { TranslateService } from "@ngx-translate/core";
import { isSupported } from 'firebase/analytics';
import { Subscription } from 'rxjs';
import { CustomFirebaseAnalyticsProvider } from 'src/app/services/CustomFirebaseAnalyticsProvider';
import { CapacitorCookieService } from 'src/app/services/capacitor-cookie.service';
import { CustomValidator } from '../../../validators/customvalidators';
import { URLS } from '../../_config/api.config';
import { TwoFactorAuthenticationComponent } from '../../components/two-factor-authentication/two-factor-authentication.component';
import { RegisterPage } from '../../pages/register/register.page';
import { ApiService } from '../../services/api-data.service';
import { CartService } from '../../services/cart.service';
import { CommonService } from '../../services/common.service';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import { ForgotPasswordComponent } from './../../components/forgot-password/forgot-password.component';
import { GetPlatformService } from './../../services/get-platform.service';
@Component({
	selector: 'app-login',
	templateUrl: './login.page.html',
	styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
	@ViewChild('emailInput', { static: false }) emailInput!: IonInput;

	private subscription: Subscription = new Subscription();
	public isLoading: boolean = false;
	public passwordType = 'password';
	public selected_Product: any;
	public product_value: any;
	public is_sample: boolean;
	rememberMe: boolean = false;
	public validation_messages = {
		email: [
			{ type: 'required', message: 'loginComponent.emailormobileRequired' },
			{ type: 'invalidEmail', message: 'loginComponent.emailormobileInvalid' }
		],
		password: [
			{ type: 'required', message: 'loginComponent.passwordRequired' },
		],
	}

	loginFromGroup: FormGroup;

	constructor(
		public getPlatformService: GetPlatformService,
		private modalService: ModalService,
		private formBuilder: FormBuilder,
		public commonService: CommonService,
		private dataService: ApiService,
		private toastService: ToastService,
		public navCtrl: NavController,
		public cartService: CartService,
		public platform: Platform,
		public CustomFirebaseAnalytics: CustomFirebaseAnalyticsProvider,
		public capacitorCookieService: CapacitorCookieService,
		private activatedRoute: ActivatedRoute,
		public translate: TranslateService
	) {
	}

	ngOnInit() {
		isSupported().then(() => {
			this.CustomFirebaseAnalytics.trackEvent('Login', '/login');  //Google Analytics Log In Event
		})
		this.loginFromGroup = this.initializeLoginFromGroup();
		this.rememberMeCheck()
		if (!this.getPlatformService.isDesktop) {
			this.activatedRoute.queryParams.subscribe((route: any) => {
				this.product_value = +route.product_value;
				if (route.selected_Product) {
					this.selected_Product = JSON.parse(route.selected_Product);
				}
			})
		}
		setTimeout(() => {
			if (this.emailInput) {
				this.emailInput.setFocus();
			}
		}, 500);
	}

	closeModal() {
		if (this.getPlatformService.isDesktop) {
			this.modalService.dismissModal();
		} else {
			this.navCtrl.back();
		}
	}


	rememberMeCheck() {

		// let remember = this.commonService.cookieGet('rememberMe');
		let remember = this.commonService.localStorageGet('rememberMe');

		this.capacitorCookieService.getCookies();
		if (remember && remember == 'yes') {
			let user = this.commonService.localStorageGet('info');
			this.rememberMe = true;
			if (user) {
				let decryptedData = this.commonService.decryptData(user)
				this.loginFromGroup = this.initializeLoginFromGroup(JSON.parse(decryptedData));
			}
		} else {
			this.rememberMe = false;
		}
	}

	openRegisterModal() {
		if (this.getPlatformService.isDesktop) {
			this.closeModal();
			setTimeout(() => {
				let options: any = {
					id: "registerModal",
					backdropDismiss: false,
					componentProps: {
						selected_Product: this.selected_Product,
						product_value: this.product_value,
						is_sample: this.is_sample
					}
				};
				options['component'] = RegisterPage;
				this.modalService.openModal(options)
			}, 300);
		} else {
			this.navCtrl.navigateForward("/" + this.commonService.currentLang + '/register');
		}
	}

	get f1() { return this.loginFromGroup.controls; }

	async login() {
		for (const i in this.f1) {
			this.f1[i].markAsDirty();
			this.f1[i].updateValueAndValidity();
			this.f1[i].markAsTouched();
		}
		if (this.loginFromGroup.valid) {
			const userInfo = {
				"user_name": this.f1.email.value ? this.f1.email.value.trim() : null,
				"password": this.f1.password.value,
				"platform": this.platform.is("mobileweb") || this.platform.is("ipad") ? "WEB" : this.platform.is("android") ? "ANDROID" : this.platform.is("ios") ? "IOS" : "WEB"
			}

			if (this.platform.is("ios") || this.platform.is("android")) {
				userInfo['notification_token'] = this.commonService.cookieGet('notification_token');
			}
			const encryptedData = this.commonService.encryptData(JSON.stringify(userInfo));
			if (!encryptedData) {
				return;
			}
			const params = {
				data: encryptedData
			}
			this.isLoading = true;

			await this.subscription.add(
				this.dataService.post(URLS.login, params).subscribe(data => {
					if (data['code'] == 200) {

						//Remember Me
						if (this.rememberMe) {
							this.commonService.cookieSet('rememberMe', 'yes');
							this.commonService.cookieSet('info', encryptedData);
							this.capacitorCookieService.setCookie('rememberMe', 'yes');
							this.capacitorCookieService.setCookie('info', encryptedData);
							this.commonService.localStorageSet('rememberMe', 'yes');
							this.commonService.localStorageSet('info', encryptedData);
						}
						else {
							this.commonService.cookieSet('rememberMe', 'no');
							this.commonService.cookieDelete('info');
							this.commonService.localStorageSet('rememberMe', 'no');

							this.capacitorCookieService.setCookie('rememberMe', 'no');
						}

						//User Login
						if (data['data']) {
							this.commonService.localStorageSet('username', data['data']['customer_email']);
							if (data['data']['session_token']) {
								this.commonService.accessToken = data['data']['session_token'];
							}
							if (data['data']['uuid']) {
								this.commonService.localStorageSet('uuid', data['data']['uuid']);
							}
							if (data['data']['mobile_no']) {
								this.commonService.localStorageSet('mobile_no', data['data']['mobile_no']);
							}
							if (data['data']['is_2fa_enabled']) {   //if 2FA enabled
								setTimeout(() => {
									this.commonService.localStorageSet('from_login', true);
									let options: any = {
										id: "twoFaModal",
										backdropDismiss: false,
										componentProps: {
											selected_Product: this.selected_Product,
											product_value: this.product_value,
											is_sample: this.is_sample
										}
									};
									options['component'] = TwoFactorAuthenticationComponent;
									this.modalService.openModal(options)
								}, 300);
							}
							else {   //if 2FA disabled
								if (data['data']['session_token']) {
									this.commonService.localStorageSet('accessToken', data['data']['session_token']);
								}
								this.commonService.localStorageSet('from_login', false);
								this.modalService.dismissAllModal();

								//Directly Add to cart After login
								if (this.selected_Product && this.product_value) {
									if (this.is_sample) {
										this.cartService.addIntoCart(this.selected_Product, this.product_value, this.is_sample).then(() => {
											this.navCtrl.navigateForward("/" + this.commonService.currentLang + '/cart', { animated: false });
										});
									} else {
										this.cartService.addIntoCart(this.selected_Product, this.product_value).then(() => {
											this.navCtrl.navigateForward("/" + this.commonService.currentLang + '/cart', { animated: false });
										});
									}
								}
								this.cartService.isloggedIn = true;
								this.navCtrl.navigateForward("/" + this.commonService.currentLang + '/home', { animated: false });
								this.commonService.setEvent('profileData', null);
							}
						}
						if (this.getPlatformService.isDesktop) {
							this.closeModal();
						}
						this.isLoading = false;
					} else {
						this.cartService.isloggedIn = false;
						this.isLoading = false;
						this.toastService.displayToast(data['message'], null, 'danger', true);
					}
				}, error => {
					this.cartService.isloggedIn = false;
					this.isLoading = false;
				})
			)
		} else {
			return;
		}
	}

	initializeLoginFromGroup(data?) {
		return this.formBuilder.group({
			email: new FormControl(data && data.user_name ? data.user_name : null, {
				validators: Validators.compose([Validators.required, CustomValidator.emailorMobileValidator]),
			}),
			password: new FormControl(data && data.password ? data.password : null, {
				validators: Validators.compose([Validators.required]),
			})
		})
	}

	forgotPasswordOpen() {
		this.modalService.dismissModal();
		let options: any = {
			id: "forgotPassword",
			backdropDismiss: false,
		};
		options['component'] = ForgotPasswordComponent;
		options['componentProps'] = {
			id: "forgotPassword",
		};
		this.modalService.openModal(options)
	}

	rememberMeClick() {
		this.rememberMe = !this.rememberMe;
	}

}
