import { DOCUMENT, Location } from '@angular/common';
import { EventEmitter, Inject, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
	providedIn: 'root'
})
export class TranslateConfigService {
	@Output() selectedLocation: any = new EventEmitter();

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private translate: TranslateService,
		public commonService: CommonService,
		public router: Router,
		public location: Location,
	) {
	}

	getDefaultLanguage() {
		let language = this.commonService.localStorageGet('locale');
		if (language) {
			this.translate.setDefaultLang(language);
			if (language === 'en') {
				this.setDir('ltr');
			}
			else {
				this.setDir('rtl')
			}
		}
		else {
			language = 'en';
			this.translate.setDefaultLang('en');
			this.setDir('ltr')
		}
		return language;

	}

	setLanguage(setLang) {
		if (!setLang) { setLang = 'en'; }
		this.translate.use(setLang);
		this.commonService.localStorageSet('locale', setLang);
		this.commonService.currentLang = setLang;

	}

	setDir(dir: string): void {
		if (dir == 'rtl') {
			this.commonService.domDirectionLTR = false;
			this.document.documentElement.dir = 'rtl';
		} else {
			this.commonService.domDirectionLTR = true;
			this.document.documentElement.dir = 'ltr';
		}
	}

	locationChange() {
		this.selectedLocation.emit();
	}


}
