import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MenusComponent } from './components/menus/menus.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

const routes: Routes = [

	{
		path: ":language",
		children: [
			{
				path: '',
				loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
			},
			{
				path: 'tabs',
				loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
			},
			{
				path: 'login',
				loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
			},
			{
				path: 'register',
				loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
			},
			{
				path: 'cart-modal',
				loadChildren: () => import('./pages/cart-modal/cart-modal.module').then(m => m.CartModalPageModule)
			},
			{
				path: 'checkout-step-1',
				loadChildren: () => import('./pages/checkout-step1/checkout-step1.module').then(m => m.CheckOutStep1PageModule)
			},
			{
				path: 'checkout-step-2',
				loadChildren: () => import('./pages/checkout-step2/checkout-step2.module').then(m => m.CheckOutStep2PageModule)
			},
			{
				path: 'checkout-step-review',
				loadChildren: () => import('./pages/checkout-step-review/checkout-step-review.module').then(m => m.CheckOutStepReviewPageModule)
			},
			{
				path: 'register',
				loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
			},
			{
				path: 'add-edit-address',
				loadChildren: () => import('./pages/add-edit-address/add-edit-address.module').then(m => m.AddEditAddressPageModule)
			},
			{
				path: 'add-edit-payment-card',
				loadChildren: () => import('./pages/add-edit-payment-card/add-edit-payment-card.module').then(m => m.AddEditPaymentCardPageModule)
			},
			{
				path: 'category',
				component: MenusComponent
			},
			{
				path: 'account-dashboard',
				loadChildren: () => import('./components/account-dashboard/account-dashboard.module').then(m => m.AccountDashboardModule)
			},
			{
				path: 'order-details',
				loadChildren: () => import('./pages/account-details/account-details.module').then(m => m.AccountDetailsPageModule)
			},
			{
				path: 'product-detail',
				loadChildren: () => import('./pages/product-detail/product-detail.module').then(m => m.ProductDetailPageModule)
			},
			{
				path: 'change-password',
				loadChildren: () => import('./components/change-password/change-password.module').then(m => m.ChangePasswordModule)
			},
			{
				path: 'career',
				loadChildren: () => import('./pages/career/career.module').then(m => m.CareerPageModule)
			},
			{
				path: 'contact-us',
				loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsPageModule)
			},
			{
				path: '**',
				data: {
					title: "Page not found - 404",
				},
				component: NotFoundComponent
			},
		]
	},
	{
		path: '',
		redirectTo: '/en',
		pathMatch: 'full',
	},


];
@NgModule({
	imports: [
		RouterModule.forRoot(routes,
			{
				scrollPositionRestoration: 'enabled'
				, anchorScrolling: 'enabled',
				scrollOffset: [0, 64],
				preloadingStrategy: PreloadAllModules,
				initialNavigation: 'enabledBlocking'
			})
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
