import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Capacitor, CapacitorCookies } from '@capacitor/core';


@Injectable({
	providedIn: 'root'
})
export class CapacitorCookieService {

	constructor(@Inject(DOCUMENT) private document: Document) { }
	getCookies = () => {
		return this.document.cookie;
	};

	setCookie(key, value) {
		this.document.cookie = key + '=' + value;
	};

	setCapacitorCookie = async () => {
		if (Capacitor.getPlatform() !== 'web') {
			await CapacitorCookies.setCookie({
				// url: 'http://example.com',
				key: 'language',
				value: 'en',
			});
		}
		else {
			this.setCookie('language', 'en');
		}
	};


	deleteCookie = async () => {
		if (Capacitor.getPlatform() !== 'web') {
			await CapacitorCookies.deleteCookie({
				url: 'https://example.com',
				key: 'language',
			});
		}
		else {
			this.setCookie('language', '');
		}
	};

	clearCookiesOnUrl = async () => {
		if (Capacitor.getPlatform() !== 'web') {
			await CapacitorCookies.clearCookies({
				url: 'https://example.com',
			});
		}
		else {
			// no-op or handle individually
		}
	};

	clearAllCookies = async () => {
		if (Capacitor.getPlatform() !== 'web') {
			await CapacitorCookies.clearAllCookies();
		}
		else {
			// Optionally clear all document cookies manually
			this.clearAllDocumentCookies();
		}
	};

	clearAllDocumentCookies() {
		const cookies = this.document.cookie.split(';');
		for (const cookie of cookies) {
			const eqPos = cookie.indexOf('=');
			const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
			this.document.cookie = name + `=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
		}
	}
}