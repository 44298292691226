import { DOCUMENT, Location, isPlatformServer } from "@angular/common";
import {
	Component,
	Inject,
	OnInit,
	PLATFORM_ID,
	Renderer2,
} from "@angular/core";
import { Meta, platformBrowser, Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { FirebaseAnalytics } from '@awesome-cordova-plugins/firebase-analytics/ngx';
import { App } from "@capacitor/app";
import { Network } from "@capacitor/network";
import { SplashScreen } from "@capacitor/splash-screen";
import {
	AlertController,
	ModalController,
	NavController,
	Platform,
} from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { getAnalytics, isSupported } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { Subject, Subscription } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { register } from 'swiper/element/bundle';
import { CapacitorCookieService } from "./services/capacitor-cookie.service";
import { CommonService } from "./services/common.service";
import { GetPlatformService } from "./services/get-platform.service";
import { ModalService } from "./services/modal.service";
import { PushnotificationService } from "./services/pushnotification.service";
import { ToastService } from "./services/toast.service";
import { TranslateConfigService } from "./services/translate-config.service";
import { ApiService } from "./services/api-data.service";
import { URLS } from "./_config/api.config";

register();

@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
	isNetworkConnected: boolean = true;
	isNoInternetModalOpen: boolean = false;
	networkListener: any;
	showSplash: any;
	closed$ = new Subject<any>();
	canonicalUrl: string;
	private subscription: Subscription = new Subscription();

	constructor(
		private renderer2: Renderer2,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private getPlatformService: GetPlatformService,
		private commonService: CommonService,
		private platform: Platform,
		public modalController: ModalController,
		private alertController: AlertController,
		private router: Router,
		public toastService: ToastService,
		private modalService: ModalService,
		private modalCtrl: ModalController,
		public capacitorCookieService: CapacitorCookieService,
		private pushNotif: PushnotificationService,
		private titleService: Title,
		public navCtrl: NavController,
		private firebaseAnalytics: FirebaseAnalytics,
		private translateConfigService: TranslateConfigService,
		@Inject(PLATFORM_ID) private platformId: Object,
		@Inject(DOCUMENT) private document: Document,
		private metaTagService: Meta,
		private location: Location,
		private dataService: ApiService,
	) {
		this.translate.addLangs(["en", "ar"]);

		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {

			const title = this.getTitle(
				router.routerState,
				router.routerState.root
			);
			if (title.length) {
				this.titleService.setTitle(
					"Juman Marketplace | " +
					title[title.length - 1]
				);
			}
			this.metaTagService.updateTag({
				property: "og:title",
				content: "Juman Marketplace | " +
					title[title.length - 1],
			});

			this.metaTagService.updateTag({
				name: "description",
				content: "Juman Marketplace is a digital E-commerce platform linking finishing building materials manufacturers, contractors & retailers. Effortless navigation, rapid shipping via top logistics, quick sample delivery. Your one-stop solution for all building materials requirements.",
			});
			this.metaTagService.updateTag({
				property: "og:description",
				content: "Juman Marketplace is a digital E-commerce platform linking finishing building materials manufacturers, contractors & retailers. Effortless navigation, rapid shipping via top logistics, quick sample delivery.Your one-stop solution for all building materials requirements.",
			});
			this.metaTagService.updateTag({
				name: "keywords",
				content: "Marketplace, Juman Market, E-commerce, Juman Market Ceramics, Tiles, Floor, Wall, Ceilings, Wall paper, Builder, Banner, Category, Group, Coupon, Discount",
			});
		});
	}

	checkLanguageInUrl() {
		let defaultLanguage = this.commonService.localStorageGet('locale');
		this.commonService.currentLang = defaultLanguage;
		if (defaultLanguage.includes('ar')) {
			this.translateConfigService.setLanguage("ar");
			this.translateConfigService.setDir("rtl");
			this.translate.use('ar');
			this.translate.setDefaultLang("ar");

			// Perform some action if URL contains /en
		} else {
			this.translateConfigService.setLanguage("en");
			this.translateConfigService.setDir("ltr");
			this.translate.use('en');
			this.translate.setDefaultLang("en");

			// Perform default action
		}
	}

	ngOnInit() {
		let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		let defaultLangu;
		let defaultdir;
		console.log('Time Zone:', timeZone);

		if (timeZone == 'Asia/Calcutta') {
			defaultLangu = 'en';
			defaultdir = 'ltr'
		}
		else {
			defaultLangu = 'ar';
			defaultdir = 'rtl'
		}

		const storedLang = this.commonService.localStorageGet('locale');
		const storedLang2 = !storedLang ? defaultLangu : storedLang;
		const languageDir = storedLang2 == 'ar' ? "rtl" : "ltr";
		this.translate.use(storedLang2 || defaultLangu);
		this.translateConfigService.setLanguage(storedLang2);
		this.translateConfigService.setDir(languageDir || defaultdir);
		let isLoggedIn = this.commonService.localStorageGet('accessToken') ? true : false;

		// if (this.router.url.includes('/login') || isLoggedIn) {
		// 	this.languageChanged();
		// }

		this.getPlatformService.getPlatformType();
		this.updateCanonicalTag('/' + storedLang2 + '/login');
		this.initializeApp();
	}

	async languageChanged() {
		try {

			// If the user is logged in, notify the backend of the language change
			const selectedLanguage = this.commonService.localStorageGet("locale");

			const params = {
				language: selectedLanguage.toUpperCase(),
			};

			await this.subscription.add(
				this.dataService.post(URLS.languageChange, params).subscribe(
					async (data) => {
						if (data["code"] == 200) {
							this.urlLanguageChange();
						}
					},
					(error) => {
					}
				)
			);

		} catch (error) {
			console.error("Error during language change:", error);
		}
	}

	urlLanguageChange() {
		// Get the current URL and query parameters
		const currentUrl = this.router.url.split('?')[0];
		const queryParams = { ...this.route.snapshot.queryParams };

		// Replace language segment in the URL
		const urlSegments = currentUrl.split('/');
		// Assuming the language is always in the second position (e.g., /en/home)
		urlSegments[1] = this.commonService.localStorageGet("locale");

		// Join the segments back into a valid URL
		let newUrl = urlSegments.join('/');

		// Optionally, clean up the URL (for example, sanitize the URL by removing unwanted characters)
		newUrl = decodeURIComponent(newUrl)
			.trim()
			.replace(/(?<=\/)[^a-zA-Z0-9-]|[^a-zA-Z0-9-/]|['™®©()\[\]]/g, '-')
			.replace(/\s+/g, '-')
			.replace(/-+/g, '-');

		// Use Angular Router to navigate to the updated URL with existing query parameters
		this.router.navigate([newUrl], { queryParams, replaceUrl: true });
	}

	updateCanonicalTag(url: string): void {
		// Generate the canonical URL dynamically
		let canonicalUrl = `https://www.jumanmarket.com${url}`;
		if (url === '/en' || url === '/ar') {
			canonicalUrl += '/home';
		}

		// On the server, ensure the canonical tag is rendered
		if (isPlatformServer(this.platformId)) {
			this.metaTagService.updateTag({ rel: 'canonical', href: canonicalUrl });
		} else {
			// On the client, dynamically update the canonical tag
			this.metaTagService.removeTag('rel="canonical"'); // Remove existing
			this.metaTagService.addTag({ rel: 'canonical', href: canonicalUrl });
		}
	}

	initializeApp() {
		// Initialize Firebase
		SplashScreen.hide();
		if (!isPlatformServer(this.platformId)) {
			isSupported().then(() => {
				const app = initializeApp(environment.firebaseConfig);
				const analytics = getAnalytics(app);
				setTimeout(() => {
					this.firebaseAnalytics.setEnabled(true);
				}, 300);
			})
		}

		if (!isPlatformServer(this.platformId)) {
			let networkStatus: any = Network.getStatus();
			networkStatus.then((data) => {
				if (!this.getPlatformService.isDesktop) {
					if (data && data.connected) {
						this.commonService.isNetworkConnected = true;
						let isModalOpen;
						if (!isPlatformServer(this.platformId)) {
							this.modalCtrl.getTop().then((data) => {
								isModalOpen = data;
								if (isModalOpen) {
									this.modalController.dismiss();
								}
							});
						}
						this.commonService.isNoInternetModalOpen = false;
					} else {
						this.commonService.isNetworkConnected = false;
						this.commonService.noInternetModal();
					}
				}
			});
			this.commonService.isNoInternetModalOpen = false;
		}
		if (!this.getPlatformService.isDesktop) {
			this.commonService.checkNetworks();
		}

		if (!this.getPlatformService.isDesktop) {
			this.showSplash = true;
		}

		this.commonService.routerEventVariable = this.router.events
			.pipe(
				filter((e) => e instanceof NavigationEnd),
				takeUntil(this.closed$)
			)
			.subscribe((event) => {
				if (this.router.url.includes("home/payment-loading")) {
					this.showSplash = false;
				}
			});

		this.platform.ready().then(() => {
			// Capture the app start time
			if (!this.getPlatformService.isDesktop) {
				let checkappStartTime = localStorage.getItem('appStartTime');
				if (!checkappStartTime) {
					let appStartTime = JSON.stringify(+new Date());
					localStorage.setItem('appStartTime', appStartTime)
				}
				setTimeout(() => {
					// SplashScreen.hide();
					this.showSplash = false;
					this.router.navigate(["/" + this.commonService.currentLang + "/home"]);
				}, 3000);
			}

			//Initialize Notification
			isSupported().then(() => {
				this.firebaseAnalytics.setEnabled(true);
			})

			this.pushNotif.initPush();
			this.backButtonEvent();


		});
	}

	backButtonEvent() {
		this.platform.backButton.subscribeWithPriority(0, () => {
			if (!isPlatformServer(this.platformId)) {
				this.modalController.getTop().then((data) => {
					if (this.router.url == `/home` && !data) {
						this.presentAlert();
					} else if (data) {
						if (this.isNetworkConnected) {
							this.modalController.dismiss();
						}
					}
					if (this.router.url.includes("home/order-thank-you")) {
						this.navCtrl.navigateRoot("/" + this.commonService.currentLang + "/home", { animated: false });
					} else {
						if (!isPlatformServer(this.platformId)) {
							window.history.back();
						}
					}
				});
			}

		});
	}

	async presentAlert() {
		const alert = await this.alertController.create({
			header: this.translate.instant("Exit App"),
			message: this.translate.instant("Are you sure you want to exit app?"),
			buttons: [
				{
					text: this.translate.instant("no"),
					role: "cancel",
					handler: () => { },
				},
				{
					text: this.translate.instant("yes"),
					role: "confirm",
					handler: () => {
						App.exitApp();
					},
				},
			],
		});

		await alert.present();

		const { role } = await alert.onDidDismiss();
	}


	public getTitle(state, parent) {
		const data = [];
		if (parent && parent.snapshot.data && parent.snapshot.data.title) {
			data.push(parent.snapshot.data.title);
		}

		if (state && parent) {
			data.push(...this.getTitle(state, state.firstChild(parent)));
		}
		return data;
	}
}
