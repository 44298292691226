<swiper-container *ngIf="TopBannerData && TopBannerData.length" [options]="topBannerOpts" #topSlides id="topSlides"
	class="slides top-banner-swiper top-slider in-english swiper-container banner-swiper"
	[attr.pagination]="getPlatformService.isDesktop && pager" [ngClass]="[ pager ? '' : 'single-slide']">
	@for(dataObj of TopBannerData;track dataObj;let i = $index){
	<swiper-slide>
		<div class="slide-content"
			(click)="bannerToProduct(dataObj , dataObj['redirecton_details'], dataObj['banner_id'])">
			<div class="top-banner-background">
				<img fetchpriority="high"
					[src]="getPlatformService.isDesktop ? dataObj['image_url_en'] : dataObj['mobile_image_url_en'] ? dataObj['mobile_image_url_en'] : dataObj['image_url_en']"
					[ngClass]="dataObj['is_default_banner'] ? 'defaultbanner':'notdefaultbanner'" alt="Jazeera">
			</div>
			<div class="title-section pr-3p pl-1p text-right-rtl"
				*ngIf="!dataObj['is_default_banner'] && (dataObj['banner_title_en'] || dataObj['banner_description_en'])">
				<div class="title">{{dataObj['banner_title_en']}}</div>
				<div class="text">{{dataObj['banner_description_en']}}</div>
				<div class="login-button mt-20">
					<ion-button aria-label="cta action button"
						(click)="bannerToProduct(dataObj , dataObj['redirecton_details'], dataObj['banner_id'])">
						{{'checkoutStepReview.PlaceOrder' | translate }}
					</ion-button>
				</div>
				<div class="custom-button hide-content">
					<ion-button aria-label="cta action button">
						<div>
							<ion-icon alt="Jazeera" src="assets/icon/whiteRightArrow.svg"></ion-icon>
						</div>
					</ion-button>
				</div>
			</div>
			<div class="title-section-mobile"
				*ngIf="!dataObj['is_default_banner']  && (dataObj['banner_title_en'] || dataObj['banner_description_en'])">
				<div class="top-caption ion-text-start pl-20">{{dataObj['banner_title_en']}}</div>
				<div class="lower-caption ion-text-start pl-20">{{dataObj['banner_description_en']}}</div>
			</div>
			<div class="right-arrow-mobile hide-content" *ngIf="!dataObj['is_default_banner']">
				<ion-button aria-label="cta action button"
					(click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])">
					<div>
						<ion-icon alt="Jazeera" src="assets/icon/whiteRightArrow.svg"></ion-icon>
					</div>
				</ion-button>
			</div>
		</div>
		<div class="slider-arrow"
			(click)="bannerToProduct(dataObj , dataObj['redirecton_details'], dataObj['banner_id'])"
			*ngIf="getPlatformService.isDesktop">
			<div class="icon-container custom-prev" (click)="slideTo('prev')">
				<ion-icon alt="Jazeera" src="assets/icon/slider-left-arrow.svg"></ion-icon>
			</div>
			<div class="icon-container custom-next" (click)="slideTo('next')">
				<ion-icon alt="Jazeera" src="assets/icon/slider-left-arrow.svg" class="rotate-arrow"></ion-icon>
			</div>
		</div>
	</swiper-slide>
	}
	<!-- </div> -->
</swiper-container>
<swiper-container #topArSlides class="slides top-slider in-arabic" [attr.pager]="getPlatformService.isDesktop && pager"
	[options]="topBannerOpts" *ngIf="TopBannerData && TopBannerData.length && !commonService.domDirectionLTR"
	(ionSlideTouchEnd)="startAutoPlay()">
	<swiper-slide *ngFor="let dataObj of TopBannerData;index as i">
		<div class="slide-content"
			(click)="bannerToProduct(dataObj , dataObj['redirecton_details'], dataObj['banner_id'])">
			<div class="top-banner-background">
				<img fetchpriority="high"
					[src]="getPlatformService.isDesktop ? dataObj['image_url_ar'] : dataObj['mobile_image_url_ar']"
					[ngClass]="dataObj['is_default_banner'] ? 'defaultbanner':'notdefaultbanner'" alt="Jazeera">
			</div>
			<div class="title-section pr-3p pl-1p text-right-rtl"
				*ngIf="!dataObj['is_default_banner']  && (dataObj['banner_title_ar'] || dataObj['banner_description_ar'])">
				<div class="title">{{dataObj['banner_title_ar']}}</div>
				<div class="text">{{dataObj['banner_description_ar']}}</div>
				<div class="login-button mt-20">
					<ion-button aria-label="cta action button"
						(click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])">
						{{'checkoutStepReview.PlaceOrder' | translate }}
					</ion-button>
				</div>
				<div class="custom-button-arabic-web-home hide-content" *ngIf="!dataObj['is_default_banner']">
					<ion-button aria-label="cta action button">
						<div>
							<ion-icon alt="Jazeera" src="assets/icon/whiteRightArrow.svg"></ion-icon>
						</div>
					</ion-button>
				</div>

			</div>
			<div class="title-section-mobile-arabic"
				*ngIf="!dataObj['is_default_banner']  && (dataObj['banner_title_ar'] || dataObj['banner_description_ar'])">
				<div class="top-caption ion-text-start pl-20">{{dataObj['banner_title_ar']}}</div>
				<div class="lower-caption ion-text-start pl-20">{{dataObj['banner_description_ar']}}</div>
			</div>
			<div class="right-arrow-mobile-arabic hide-content" *ngIf="!dataObj['is_default_banner']">
				<ion-button aria-label="cta action button"
					(click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])">
					<div>
						<ion-icon alt="Jazeera" class="rotate-180" src="assets/icon/whiteRightArrow.svg"></ion-icon>
					</div>
				</ion-button>
			</div>
		</div>
		<div class="slider-arrow"
			(click)="bannerToProduct(dataObj , dataObj['redirecton_details'], dataObj['banner_id'])"
			*ngIf="getPlatformService.isDesktop">
			<div class="icon-container" (click)="slideTo(i-1,$event)">
				<ion-icon alt="Jazeera" alt="Jazeera" src="assets/icon/slider-left-arrow.svg"
					class="rotate-arrow"></ion-icon>
			</div>
			<div class="icon-container" (click)="slideTo(i+1,$event)">
				<ion-icon alt="Jazeera" alt="Jazeera" src="assets/icon/slider-left-arrow.svg"></ion-icon>
			</div>
		</div>
	</swiper-slide>
</swiper-container>
<div class="secure-support-block mt-30" *ngIf="topBanner">
	<div class="section">
		<img src="/assets/icon/secure.svg" alt="Jazeera" height="100%" width="100%">
		<div class="right-side">
			<span class="title">{{"secure.securePayment" | translate}}</span>
			<span class="text">{{"secure.description" | translate}}</span>
		</div>
	</div>
	<div class="vertical-line"></div>
	<div class="section">
		<img src="/assets/icon/support.svg" alt="Jazeera" height="100%" width="100%">
		<div class="right-side">
			<span class="title">{{"support.24by7support" | translate}}</span>
			<span class="text">{{"support.description" | translate}}</span>
		</div>
	</div>
</div>
<swiper-container #slides class="slides middle-banner" (ionSlideTouchEnd)="startAutoPlay()"
	(ionSlideTransitionEnd)="slideChanged()" [options]="middleSlideOpts"
	*ngIf="multiBanner && !commonService.domDirectionLTR" dir="rtl">
	<swiper-slide *ngFor="let dataObj of bottomBannerData">
		<div class="slide-content"
			(click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])"
			[ngStyle]="{'background': 'url(' + dataObj['gray_image_url'] + ') no-repeat 0 0 / cover'}">
			<div class="title-section pr-3p pl-1p text-right-rtl"
				*ngIf=" (dataObj['banner_title_ar'] || dataObj['banner_description_ar'])">
				<div class="title">{{ dataObj['banner_title_ar'] }}</div>
				<div class="text">{{ dataObj['banner_description_ar'] }}</div>
				<div class="custom-button multi-banner-button hide-content">
					<ion-button aria-label="cta action button">
						<div class="button-text">
							{{"ctaAction" | translate}}
						</div>
						<div>
							<ion-icon alt="Jazeera" src="assets/icon/whiteRightArrow.svg"></ion-icon>
						</div>
					</ion-button>
				</div>
			</div>
			<div class="image-section">
				<img fetchpriority="high" src="{{ dataObj['image_url_ar'] }}"
					[ngClass]="dataObj['is_default_banner'] ? 'defaultbanner':'notdefaultbanner'" alt="Jazeera">
			</div>
		</div>
	</swiper-slide>
</swiper-container>
<swiper-container #slides class="slides middle-banner" (ionSlideTransitionEnd)="slideChanged()"
	(ionSlideTouchEnd)="startAutoPlay()" [options]="slideOpts" *ngIf="multiBanner && commonService.domDirectionLTR"
	dir="ltr">
	<swiper-slide *ngFor="let dataObj of bottomBannerData">
		<div class="slide-content"
			(click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])"
			[ngStyle]="{'background': 'url(' + dataObj['gray_image_url'] + ') no-repeat 0 0 / cover'}">
			<div class="title-section pr-3p pl-1p text-right-rtl"
				*ngIf="(dataObj['banner_title_en'] || dataObj['banner_description_en'])">
				<div class="title">{{ dataObj['banner_title_en'] }}</div>
				<div class="text">{{ dataObj['banner_description_en'] }}</div>
				<div class="custom-button multi-banner-button hide-content">
					<ion-button aria-label="cta action button">
						<div class="button-text">
							{{"ctaAction" | translate}}
						</div>
						<div>
							<ion-icon alt="Jazeera" src="assets/icon/whiteRightArrow.svg"></ion-icon>
						</div>
					</ion-button>
				</div>
			</div>
			<div class="image-section">
				<img fetchpriority="high" src="{{ dataObj['image_url_en'] }}" alt="Jazeera">
			</div>
		</div>
	</swiper-slide>
</swiper-container>
<swiper-container #slidesMobile class="mobile-slides" (ionSlideTouchEnd)="startAutoPlay()" [options]="middleSlideOpts"
	*ngIf="multiBanner && commonService.domDirectionLTR">
	<swiper-slide *ngFor="let dataObj of bottomBannerData">
		<div class="slide-content-mobile"
			(click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])">
			<div class="title-scetion-mobile">
				<img fetchpriority="high" src="{{ dataObj['image_url_en'] }}" alt="Jazeera">
			</div>
			<div class="title-section ion-text-start"
				*ngIf="(dataObj['banner_title_en'] || dataObj['banner_description_en'])">
				<div class="title">
					{{ dataObj['banner_title_en'] }}
				</div>
				<div class="text pt-10">
					{{ dataObj['banner_description_en'] }}
				</div>
			</div>
			<div class="custom-button hide-content">
				<ion-button aria-label="cta action button">
					<div>
						<ion-icon alt="Jazeera" src="assets/icon/whiteRightArrow.svg"></ion-icon>
					</div>
				</ion-button>
			</div>
		</div>
	</swiper-slide>
</swiper-container>
<swiper-container #slidesMobileAr class="mobile-slides" (ionSlideTouchEnd)="startAutoPlay()" [options]="middleSlideOpts"
	*ngIf="multiBanner && !commonService.domDirectionLTR">
	<swiper-slide *ngFor="let dataObj of bottomBannerData">
		<div class="slide-content-mobile"
			(click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])">
			<div class="title-scetion-mobile-arabic">
				<img fetchpriority="high" src="{{ dataObj['image_url_ar'] }}" alt="Jazeera">
			</div>
			<div class="title-section-arabic ion-text-start"
				*ngIf="(dataObj['banner_title_ar'] || dataObj['banner_description_ar'])">
				<div class="title">
					{{ dataObj['banner_title_ar'] }}
				</div>
				<div class="text pt-10">
					{{ dataObj['banner_description_ar'] }}
				</div>
			</div>
			<div class="custom-button-arabic hide-content">
				<ion-button aria-label="cta action button">
					<div>
						<ion-icon alt="Jazeera" class="rotate-180" src="assets/icon/whiteRightArrow.svg"></ion-icon>
					</div>
				</ion-button>
			</div>
		</div>
	</swiper-slide>
</swiper-container>
<swiper-container #slides class="slides middle-banner" (ionSlideTouchEnd)="startAutoPlay()"
	(ionSlideTransitionEnd)="slideChanged()" [options]="slideOpts" *ngIf="gridBanner && commonService.domDirectionLTR"
	dir="ltr">
	<swiper-slide *ngFor="let dataObj of gridBannerData">
		<div class="slide-content grid-slide-content pt-65 pl-115"
			(click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])"
			[ngStyle]="{'background': 'url(' + dataObj['gray_image_url'] + ') no-repeat 0 0 / cover'}">
			<div class="title-section pr-3p pl-1p text-right-rtl"
				*ngIf="(dataObj['banner_title_en'] || dataObj['banner_description_en'])">
				<div class="title">{{ dataObj['banner_title_en'] }}</div>
				<div class="text">{{ dataObj['banner_description_en'] }}</div>
				<div class="custom-button multi-banner-button hide-content">
					<ion-button aria-label="cta action button">
						<div class="button-text">
							{{"ctaAction" | translate}}
						</div>
						<div>
							<ion-icon alt="Jazeera" src="assets/icon/whiteRightArrow.svg"></ion-icon>
						</div>
					</ion-button>
				</div>
			</div>
			<div class="grid-image-section">
				<img fetchpriority="high" src="{{ dataObj['image_url_en'] }}" alt="Jazeera">
			</div>
		</div>
	</swiper-slide>
</swiper-container>
<swiper-container #slidesGridAr class="slides middle-banner" (ionSlideTouchEnd)="startAutoPlay()"
	(ionSlideTransitionEnd)="slideChanged()" [options]="gridSlideOpts"
	*ngIf="gridBanner && !commonService.domDirectionLTR" dir="rtl">
	<swiper-slide *ngFor="let dataObj of gridBannerData">
		<div class="slide-content grid-slide-content pt-65"
			(click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])"
			[ngStyle]="{'background': 'url(' + dataObj['gray_image_url'] + ') no-repeat 0 0 / cover'}">
			<div class="title-section pr-3p pl-1p text-right-rtl"
				*ngIf="(dataObj['banner_title_ar'] || dataObj['banner_description_ar'])">
				<div class="title">{{ dataObj['banner_title_ar'] }}</div>
				<div class="text">{{ dataObj['banner_description_ar'] }}</div>
				<div class="custom-button multi-banner-button hide-content">
					<ion-button aria-label="cta action button">
						<div class="button-text">
							{{"ctaAction" | translate}}
						</div>
						<div>
							<ion-icon alt="Jazeera" src="assets/icon/whiteRightArrow.svg"></ion-icon>
						</div>
					</ion-button>
				</div>
			</div>
			<div class="grid-image-section">
				<img fetchpriority="high" src="{{ dataObj['image_url_en'] }}" alt="Jazeera">
			</div>
		</div>
	</swiper-slide>
</swiper-container>
<swiper-container #slidesGridMobile class="mobile-slides" [options]="gridSlideOpts" (ionSlideTouchEnd)="startAutoPlay()"
	*ngIf="gridBanner && commonService.domDirectionLTR">
	<swiper-slide *ngFor="let dataObj of gridBannerData">
		<div class="slide-content-mobile"
			(click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])">
			<div class="title-scetion-mobile">
				<img fetchpriority="high" src="{{ dataObj['image_url_en'] }}" alt="Jazeera">
			</div>
			<div class="title-section ion-text-start"
				*ngIf="(dataObj['banner_title_en'] || dataObj['banner_description_en'])">
				<div class="title">
					{{ dataObj['banner_title_en'] }}
				</div>
				<div class="text pt-10">
					{{ dataObj['banner_description_en'] }}
				</div>
			</div>
			<div class="custom-button">
				<ion-button aria-label="cta action button">
					<div>
						<ion-icon alt="Jazeera" src="assets/icon/whiteRightArrow.svg"></ion-icon>
					</div>
				</ion-button>
			</div>
		</div>
	</swiper-slide>
</swiper-container>
<swiper-container #slidesGridMobileAr class="mobile-slides" (ionSlideTouchEnd)="startAutoPlay()"
	[options]="gridSlideOpts" *ngIf="gridBanner && !commonService.domDirectionLTR">
	<swiper-slide *ngFor="let dataObj of gridBannerData">
		<div class="slide-content-mobile"
			(click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])">
			<div class="title-scetion-mobile">
				<img fetchpriority="high" src="{{ dataObj['image_url_ar'] }}"
					[ngClass]="dataObj['is_default_banner'] ? 'defaultbanner':'notdefaultbanner'" alt="Jazeera">
			</div>
			<div class="title-section ion-text-start"
				*ngIf="(dataObj['banner_title_ar'] || dataObj['banner_description_ar'])">
				<div class="title">
					{{ dataObj['banner_title_ar'] }}
				</div>
				<div class="text pt-10">
					{{ dataObj['banner_description_ar'] }}
				</div>
			</div>
			<div class="custom-button">
				<ion-button aria-label="cta action button">
					<div>
						<ion-icon alt="Jazeera" src="assets/icon/whiteRightArrow.svg"></ion-icon>
					</div>
				</ion-button>
			</div>
		</div>
	</swiper-slide>
</swiper-container>
<!-- code required -->
<!-- <div class="grid-banner ion-text-center" *ngIf="gridBanner" [ngStyle]="getBannerImage()">
    <div class="banner-container">
        <div class="banner-contents pl-55">
            <div class="banner-title">
                This is a promo area
            </div>
            <div class="banner-description">
                Ut placerat ut pretium dolor sit amet adipis
            </div>
        </div>
        <div class="custom-button cta-button pr-100" routerLink="/product-listing/">
            <ion-button>
                <div class="button-text">
                    {{"ctaAction" | translate}}
                </div>
                <div>
                    <ion-icon src="assets/icon/whiteRightArrow.svg"></ion-icon>
                </div>
            </ion-button>
        </div>
    </div>
</div> -->
<!-- display the banner on product listing page -->
<div class="product-list-banner" *ngIf="productlistBanner">
	<div class="product-list-container">
		<img [src]="bannerImage ? bannerImage : '/assets/images/slider.svg'" alt="Jazeera">
		<div class="banner-content">
			<div class="banner-title top-banner">
				{{ productTitle ? productTitle : bannerTitle }}
			</div>
			<div class="banner-description">
				{{ bannerDescription ? bannerDescription : '' }}
			</div>
		</div>
	</div>
</div>

<div *ngIf="multiBanner && bottomBannerData && getPlatformService.isDesktop">
	<div class="pager-container mb-20">
		<div class="pager" [class.active]="slideIndex == i" *ngFor="let data of bottomBannerData; index as i"
			(click)="slideTo(i)">
		</div>
	</div>
</div>

<div *ngIf="gridBanner && gridBannerData && getPlatformService.isDesktop">
	<div class="pager-container mb-20">
		<div class="pager" [class.active]="slideIndex == i" *ngFor="let data of gridBannerData; index as i"
			(click)="slideTo(i)">
		</div>
	</div>
</div>