import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { isPlatformBrowser } from '@angular/common';
import { Router } from "@angular/router";
import { FirebaseAnalytics } from '@awesome-cordova-plugins/firebase-analytics/ngx';
import { JwtInterceptor } from './_helpers/http-interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './common/shared.module';
import { CommonService } from './services/common.service';
import { TranslateConfigService } from './services/translate-config.service';
import * as Sentry from "@sentry/angular";

// Import the functions you need from the SDKs you need




// // Initialize Firebase
// isSupported().then(() => {
// 	const app = initializeApp(environment.firebaseConfig);
// 	const analytics = getAnalytics(app);
// 	const auth = getAuth(app)
// 	const firestore = getFirestore(app)
// })



@NgModule({
	declarations: [AppComponent],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		IonicModule.forRoot({ animated: false }),
		AppRoutingModule,
		SharedModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (HttpLoaderFactory),
				deps: [HttpClient]
			}
		})],
	providers: [
		TranslateConfigService,
		TranslateService,
		FirebaseAnalytics,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler(),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => { },
			deps: [Sentry.TraceService],
			multi: true,
		},
	],


})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/language/", ".json");
}

