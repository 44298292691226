import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from './toast.service';

@Injectable({
	providedIn: 'root'
})

export class ApiService {

	constructor(
		private httpClient: HttpClient,
		public toastService: ToastService
	) {

	}

	// get(url: string, params?: any): Observable<any> {
	//     return this.httpClient.get(`${url}`, { params: params });
	// }

	// post(url: string, param?: any, purpose_type?: any): Observable<any> {
	//     let obj = {}
	//     if (purpose_type) {
	//         obj['purpose-type'] = purpose_type
	//     }
	//     return this.httpClient.post(`${url}`, param, { headers: new HttpHeaders(obj) });
	// }

	// put(url: string, data?: any): Observable<any> {
	//     return this.httpClient.put(`${url}`, data);
	// }

	// patch(url: string, data?: any): Observable<any> {
	//     return this.httpClient.patch(`${url}`, data);
	// }

	// delete(url: string, data?: any): Observable<any> {
	//     return this.httpClient.delete(`${url}`, data);
	// }



	get(url: string, params?: any): Observable<any> {
		return this.httpClient.get(`${url}`, { params: params }).pipe(
			catchError(error => this.handleError(error))
		);
	}

	post(url: string, param?: any, purpose_type?: any): Observable<any> {
		const headers = purpose_type ? new HttpHeaders({ 'purpose-type': purpose_type }) : undefined;
		return this.httpClient.post(`${url}`, param, { headers }).pipe(
			catchError(error => this.handleError(error))
		);
	}

	put(url: string, data?: any): Observable<any> {
		return this.httpClient.put(`${url}`, data).pipe(
			catchError(error => this.handleError(error))
		);
	}

	patch(url: string, data?: any): Observable<any> {
		return this.httpClient.patch(`${url}`, data).pipe(
			catchError(error => this.handleError(error))
		);
	}

	delete(url: string, params?: any): Observable<any> {
		const options = params ? { params: new HttpParams({ fromObject: params }) } : {};
		return this.httpClient.delete(`${url}`, options).pipe(
			catchError(error => this.handleError(error))
		);
	}

	/**
	 * Handles HTTP errors and displays a toast notification.
	 */
	private handleError(error: any): Observable<never> {
		// Extract the error message to display in the toast
		let errorMessage = 'An unexpected error occurred';

		// Check if it's an HTTP error
		if (error?.status) {
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.statusText || error.message}`;
		} else if (error?.message) {
			errorMessage = `Message: ${error.message}`;
		}
		this.toastService.displayToast(errorMessage, null, 'danger');

		// Re-throw the error so it can still be handled elsewhere if needed
		// return throwError(() => new Error(errorMessage));
		return EMPTY;
	}



}
